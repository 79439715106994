import create from 'zustand';
import { devtools } from 'zustand/middleware';

import { PROFILE_SCHEMA_API, USER_API } from '@felixcolaci/cic-profile-page-components';

interface SearchResult {
  id: string;
  email: string;
}
interface ProfileStore {
  profile: Record<string, unknown> | undefined;
  profileSchema: Record<string, string> | undefined;
  getProfileAsync(userId?: string): Promise<void>;
  searchUserAsync(query: string): Promise<Array<SearchResult>>;
}

export const useProfileStore = create<ProfileStore>()(
  devtools((set) => ({
    profile: undefined,
    profileSchema: undefined,
    async getProfileAsync(userId?: string) {
      if (userId) {
        const [profile, profileSchema] = await Promise.allSettled<
          [Promise<Record<string, unknown>>, Promise<Record<string, string>>]
        >([
          fetch(`${USER_API}/users/${userId}`)
            .then((data) => data.json())
            .catch(console.error),
          fetch(PROFILE_SCHEMA_API)
            .then((data) => data.json())
            .catch(console.error)
        ]);

        if (profile.status === 'fulfilled') {
          set(() => ({ profile: profile.value }));
        }

        if (profileSchema.status === 'fulfilled') {
          set(() => ({ profileSchema: profileSchema.value }));
        }
      }
    },
    async searchUserAsync(query: string) {
      return fetch(`${USER_API}/users_by_email?${new URLSearchParams({ email: query })}`)
        .then((data) => (data.status === 200 ? data.json() : undefined))
        .then((data) => (data ? [{ id: data.user_id, email: data.email }] : []))
        .catch((error) => {
          console.log(error);
          return [];
        });
    }
  }))
);

export function createJSONSchema(
  json: Record<string, any>,
  parent: string = '',
  res: Record<string, any> = {}
): Record<string, string> {
  for (const key in json) {
    const propName = parent ? `${parent}.${key}` : key;
    if (typeof json[key] === 'object') {
      // We are assuming all arrays will have the same element types
      if (Array.isArray(json[key]) && json[key].length > 0) {
        // We ignore arrays where elements are objects
        if (typeof json[key][0] !== 'object') {
          res[propName] = `[${typeof json[key][0]}]`;
        }
      } else {
        createJSONSchema(json[key], propName, res);
      }
    } else {
      res[propName] = typeof json[key];
    }
  }

  return res;
}
