import type { EditableComponentProperties } from '../types';
import { callToActionComponentPropertiesFactory } from '../callToAction';
import { headingComponentPropertiesFactory } from '../heading';
import { layoutComponentPropertiesFactory } from '../layout';
import { isPrimitiveType, primitiveComponentPropertiesFactory } from '../primitive';
import { selectComponentPropertiesFactory } from '../select';

export function bootstrapComponent(type: string): EditableComponentProperties {
  switch (type) {
    case 'CALL_TO_ACTION': {
      return callToActionComponentPropertiesFactory();
    }
    case 'HEADING': {
      return headingComponentPropertiesFactory();
    }
    case 'SELECT': {
      return selectComponentPropertiesFactory();
    }
    default: {
      if (isPrimitiveType(type)) {
        return primitiveComponentPropertiesFactory(type);
      }

      return layoutComponentPropertiesFactory();
    }
  }
}
