import type { ReactNode } from 'react';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Stack from 'react-bootstrap/Stack';

import type { ProfileProperty } from '@felixcolaci/cic-profile-page-components';
import {
  getPrimitiveDefaultType,
  isProfileProperty
} from '@felixcolaci/cic-profile-page-components';

import { useProfileStore } from '../../../../../stores/profile.store';

import styles from './userPropertySelector.module.scss';

type FieldType =
  | 'boolean'
  | 'date'
  | 'email'
  | 'enum'
  | 'number'
  | 'string'
  | 'tel'
  | 'text'
  | 'url';

type PrimitiveType = boolean | number | string;
interface UserPropertySelectorProps<Value extends PrimitiveType> {
  value: ProfileProperty | Value;
  fieldType: FieldType;
  children(value: Value): ReactNode;
  onSelected(value: ProfileProperty): void;
}

export function UserPropertySelector<Value extends PrimitiveType>({
  value,
  fieldType,
  children,
  onSelected
}: UserPropertySelectorProps<Value>) {
  const profileSchema = useProfileStore((state) => state.profileSchema);

  function handleSelected(selected: string) {
    return () => {
      // Special syntax to differentiate from user entered values
      onSelected({
        userProperty: selected
      });
    };
  }

  return (
    <Stack direction="vertical" gap={1}>
      <p className="m-0">Use either one of the values below</p>
      <Card className="p-4">
        {profileSchema && (
          <Stack className={styles.options} gap={2}>
            {Object.entries(profileSchema)
              .filter(([, profileSchemaValue]) => profileSchemaValue.includes(fieldType))
              .map(([profileSchemaKey]) => {
                return (
                  <Button
                    key={profileSchemaKey}
                    variant={
                      isProfileProperty(value) && profileSchemaKey === value.userProperty
                        ? 'success'
                        : 'outline-secondary'
                    }
                    onClick={handleSelected(profileSchemaKey)}>
                    {profileSchemaKey}
                  </Button>
                );
              })}
          </Stack>
        )}
      </Card>
      <p className={`${styles.or} my-2`}>OR</p>
      {children(isProfileProperty(value) ? (getDefaultValue(fieldType) as Value) : value)}
    </Stack>
  );
}

function getDefaultValue(fieldType: FieldType) {
  switch (fieldType) {
    case 'enum': {
      return '';
    }
    default: {
      return getPrimitiveDefaultType(fieldType);
    }
  }
}
