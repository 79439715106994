import { FormEvent, useEffect } from 'react';
import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Table from 'react-bootstrap/Table';

import { SelectOptionProps } from '@felixcolaci/cic-profile-page-components';

import styles from './selectOptionManager.module.scss';

interface SelectOptionManagerProps {
  options: Array<SelectOptionProps>;
  onChange(options: Array<SelectOptionProps>): void;
}

const initialState: SelectOptionProps = {
  label: '',
  value: ''
};

export function SelectOptionManager({ options, onChange }: SelectOptionManagerProps) {
  const [formState, setFormState] = useState<SelectOptionProps>(initialState);

  // Whenever options change reset the form state
  useEffect(() => {
    setFormState(initialState);
  }, [options]);

  function addOption(e: FormEvent) {
    e.preventDefault();
    // add option and remove any pre-existing options with the same value
    const updatedOptions = [
      ...options.filter((option) => option.value !== formState.value),
      formState
    ];
    // publish changes
    onChange(updatedOptions);
  }

  function removeOption(value: string) {
    // remove desired option from options
    const updatedOptions = options.filter((option) => option.value !== value);
    // publish changes
    onChange(updatedOptions);
  }

  return (
    <Table className={styles.optionsTable}>
      <thead>
        <tr>
          <th>Label</th>
          <th>Value</th>
          <th>
            <i className="bi bi-gear-wide" />
          </th>
        </tr>
      </thead>
      <tbody>
        {options.map((item) => (
          <tr key={item.value}>
            <td>{item.label}</td>
            <td>{item.value}</td>
            <td>
              <Button variant="outline-dark" size="sm" onClick={() => removeOption(item.value)}>
                <i className="bi bi-dash" />
              </Button>
            </td>
          </tr>
        ))}
      </tbody>
      <tfoot>
        <tr>
          <td colSpan={3}>
            <InputGroup>
              <Form.Control
                placeholder="Label"
                value={formState?.label}
                onChange={(e) => {
                  setFormState((state) => {
                    return { ...state, label: e.target.value };
                  });
                }}
              />
              <Form.Control
                placeholder="Value"
                value={formState?.value}
                onChange={(e) => {
                  setFormState((state) => {
                    return { ...state, value: e.target.value };
                  });
                }}
              />
              <Button
                disabled={!formState.label || !formState.value}
                variant="outline-dark"
                size="sm"
                type="button"
                onClick={addOption}>
                <i className="bi bi-plus" />
              </Button>
            </InputGroup>
          </td>
        </tr>
      </tfoot>
    </Table>
  );
}
