import _ from 'lodash';

import type { EditableComponentProperties, PlacableComponentProperties } from '../types';
import { isProfileProperty } from './isProfileProperty.utils';
import { titleCase } from './titleCase.utils';

export function transformEditableToPlacable(
  component: Exclude<EditableComponentProperties, { type: 'LAYOUT' }>,
  profile: Record<string, unknown>,
  profileSchema: Record<string, string>
): PlacableComponentProperties {
  switch (component.type) {
    case 'CALL_TO_ACTION': {
      return component;
    }
    case 'HEADING': {
      return {
        ...component,
        value: isProfileProperty(component.value)
          ? String(_.get(profile, component.value.userProperty))
          : component.value
      };
    }
    case 'SELECT': {
      let options = component.options;
      if (isProfileProperty(component.value)) {
        const rawEnumSchema = _.get(profileSchema, component.value.userProperty);
        const prefix = 'enum:';
        options = rawEnumSchema
          .substring(rawEnumSchema.indexOf(prefix) + prefix.length)
          .trim()
          .split('|')
          .map((option) => {
            const enumProperty = option.trim();
            return {
              label: titleCase(String(/\((.+)\)/.exec(enumProperty)?.[1])) ?? '',
              value: enumProperty.replace(/\(.+\)/, '')
            };
          });
      }
      return {
        ...component,
        options,
        value: isProfileProperty(component.value)
          ? String(_.get(profile, component.value.userProperty))
          : component.value
      };
    }
    case 'PRIMITIVE': {
      switch (component.primitive.type) {
        case 'boolean': {
          return {
            ...component,
            primitive: {
              ...component.primitive,
              value: isProfileProperty(component.primitive.value)
                ? Boolean(_.get(profile, component.primitive.value.userProperty))
                : component.primitive.value
            }
          };
        }

        case 'date': {
          return {
            ...component,
            primitive: {
              ...component.primitive,
              value: isProfileProperty(component.primitive.value)
                ? String(_.get(profile, component.primitive.value.userProperty))
                : component.primitive.value
            }
          };
        }

        case 'email':
        case 'string':
        case 'tel':
        case 'text':
        case 'url': {
          return {
            ...component,
            primitive: {
              ...component.primitive,
              value: isProfileProperty(component.primitive.value)
                ? String(_.get(profile, component.primitive.value.userProperty))
                : component.primitive.value
            }
          };
        }

        case 'number': {
          return {
            ...component,
            primitive: {
              ...component.primitive,
              value: isProfileProperty(component.primitive.value)
                ? Number(_.get(profile, component.primitive.value.userProperty))
                : component.primitive.value
            }
          };
        }
      }
    }
  }
}
