import type { Dispatch, SetStateAction } from 'react';
import Form from 'react-bootstrap/Form';

import type {
  SelectComponentProperties,
  EditableComponentProperties,
  ProfileProperty
} from '@felixcolaci/cic-profile-page-components';

import { SelectOptionManager } from '../selectOptionManager';
import { UserPropertySelector } from '../userPropertySelector';

import styles from './selectEditorItems.module.scss';

interface SelectEditorItemsProps {
  formState: SelectComponentProperties<string | ProfileProperty>;
  setFormState: Dispatch<SetStateAction<EditableComponentProperties>>;
}

export function SelectEditorItems({ formState, setFormState }: SelectEditorItemsProps) {
  return (
    <>
      <Form.Group className="mb-3 col">
        <Form.Label>Label</Form.Label>
        <Form.Control
          value={formState.label}
          type="text"
          onChange={(e) => setFormState((state) => ({ ...state, label: e.target.value }))}
        />
        <Form.Text>Enter the label for the field</Form.Text>
      </Form.Group>
      <Form.Group className="mb-3 col">
        <Form.Label>Read only</Form.Label>
        <Form.Check
          checked={formState.readOnly}
          type="switch"
          onChange={(e) => setFormState((state) => ({ ...state, readOnly: e.target.checked }))}
        />
        <Form.Text>Do you want to prevent this field from being edited?</Form.Text>
      </Form.Group>
      <Form.Group className="mb-3 col">
        <Form.Label>Properties</Form.Label>
        <UserPropertySelector
          fieldType="enum"
          value={formState.value}
          onSelected={(selected) =>
            setFormState((state) => {
              if (state.type === 'SELECT') {
                return {
                  ...state,
                  options: [],
                  value: selected
                };
              }

              return state;
            })
          }>
          {(value) => (
            <div className={styles.select}>
              <SelectOptionManager
                options={formState.options}
                onChange={(options) =>
                  setFormState((state) => {
                    if (state.type === 'SELECT') {
                      if (options.length === 0) {
                        return {
                          ...state,
                          options,
                          value: ''
                        };
                      } else if (options.length === 1) {
                        return {
                          ...state,
                          options,
                          value: options[0].value
                        };
                      }

                      return {
                        ...state,
                        options,
                        value:
                          options.find((option) => option.value === state.value) === undefined
                            ? options[0].value
                            : state.value
                      };
                    }

                    return state;
                  })
                }
              />
              <Form.Group>
                <Form.Label>Value</Form.Label>
                <Form.Select
                  value={value}
                  onChange={(e) =>
                    setFormState((state) => {
                      if (state.type === 'SELECT') {
                        return {
                          ...state,
                          value: e.target.value
                        };
                      }

                      return state;
                    })
                  }>
                  {formState.options.map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
            </div>
          )}
        </UserPropertySelector>
        <Form.Text>Choose the properties for the field</Form.Text>
      </Form.Group>
      <Form.Group className="mb-3 col">
        <Form.Label>Description</Form.Label>
        <Form.Control
          value={formState.description || ''}
          type="text"
          onChange={(e) =>
            setFormState((state) => ({
              ...state,
              description: e.target.value
            }))
          }
        />
        <Form.Text>Enter the optional description for the field</Form.Text>
      </Form.Group>
    </>
  );
}
