import type { PaddingPropertyType } from '@felixcolaci/cic-profile-page-components';

interface PaddingIconProps {
  direction: PaddingPropertyType;
  height?: number;
  width?: number;
}

export function PaddingIcon({ direction, height = 19, width = 19 }: PaddingIconProps) {
  const stroke = '#ced4da';

  return (
    <svg
      style={{ minHeight: height, minWidth: width }}
      height={height}
      width={width}
      strokeWidth={4}
      viewBox={`0 0 ${height} ${width}`}>
      {/* Top bar */}
      <path d={`M0,0 L${width},0`} stroke={direction === 'top' ? stroke : undefined} />
      {/* Right bar */}
      <path d={`M${width},0 V${height}`} stroke={direction === 'right' ? stroke : undefined} />
      {/* Bottom bar */}
      <path d={`M0,${height} H${width}`} stroke={direction === 'bottom' ? stroke : undefined} />
      {/* Left bar */}
      <path d={`M0,0 V${height}`} stroke={direction === 'left' ? stroke : undefined} />
      <rect
        x={width / 4}
        y={height / 4}
        height={height / 2}
        width={width / 2}
        fill="transparent"
        stroke={stroke}
        strokeWidth={2}
      />
    </svg>
  );
}
