import type { CSSProperties } from 'react';

import { useConfigStore } from '../../../stores/config.store';

import { EditorItem } from './editorItem';

import styles from './preview.module.scss';

interface PreviewProps {
  consumedBrowserWidth: number;
}

export function Preview({ consumedBrowserWidth }: PreviewProps) {
  const content = useConfigStore((state) => state.config);

  return (
    <div
      className={styles.preview}
      style={
        {
          '--consumed-browser-width': `${consumedBrowserWidth}px`
        } as CSSProperties
      }>
      <EditorItem component={content} />
    </div>
  );
}
