import type { PlacableComponentProperties } from '../types';

import { CallToAction } from '../callToAction';
import { Heading } from '../heading';
import { Layout } from '../layout';
import { Primitive } from '../primitive';
import { Select } from '../select';

interface ComponentHostProps {
  className?: string;
  component: PlacableComponentProperties;
}

export function ComponentHost({ component, className }: ComponentHostProps) {
  switch (component.type) {
    case 'CALL_TO_ACTION':
      return <CallToAction className={className} {...component} />;
    case 'HEADING':
      return <Heading className={className} {...component} />;
    case 'PRIMITIVE':
      return <Primitive className={className} {...component} />;
    case 'LAYOUT':
      return <Layout className={className} {...component} />;
    case 'SELECT':
      return <Select className={className} {...component} />;
    default:
      return null;
  }
}
