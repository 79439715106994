import type { Dispatch, SetStateAction } from 'react';
import Form from 'react-bootstrap/Form';

import type {
  PrimitiveComponentProperties,
  EditableComponentProperties,
  ProfileProperty
} from '@felixcolaci/cic-profile-page-components';

import { UserPropertySelector } from '../userPropertySelector';

interface PrimitiveEditorItemsProps {
  formState: PrimitiveComponentProperties<ProfileProperty>;
  setFormState: Dispatch<SetStateAction<EditableComponentProperties>>;
}

export function PrimitiveEditorItems({ formState, setFormState }: PrimitiveEditorItemsProps) {
  return (
    <>
      <Form.Group className="mb-3 col">
        <Form.Label>Label</Form.Label>
        <Form.Control
          value={formState.label}
          type="text"
          onChange={(e) => setFormState((state) => ({ ...state, label: e.target.value }))}
        />
        <Form.Text>Enter the label for the field</Form.Text>
      </Form.Group>
      <Form.Group className="mb-3 col">
        <Form.Label>Read only</Form.Label>
        <Form.Check
          checked={formState.readOnly}
          type="switch"
          onChange={(e) => setFormState((state) => ({ ...state, readOnly: e.target.checked }))}
        />
        <Form.Text>Do you want to prevent this field from being edited?</Form.Text>
      </Form.Group>
      <Form.Group className="mb-3 col">
        <Form.Label>Value</Form.Label>
        {formState.primitive.type === 'boolean' ? (
          <UserPropertySelector
            fieldType="boolean"
            value={formState.primitive.value}
            onSelected={(selected) =>
              setFormState((state) => {
                if (state.type === 'PRIMITIVE') {
                  return {
                    ...state,
                    primitive: { ...state.primitive, value: selected }
                  };
                }

                return state;
              })
            }>
            {(value) => (
              <Form.Check
                aria-label="Value for the field"
                checked={value}
                onChange={(e) =>
                  setFormState((state) => {
                    if (state.type === 'PRIMITIVE') {
                      return {
                        ...state,
                        primitive: { ...state.primitive, value: e.target.checked }
                      } as EditableComponentProperties;
                    }

                    return state;
                  })
                }
                type="switch"
              />
            )}
          </UserPropertySelector>
        ) : (
          <UserPropertySelector
            fieldType={formState.primitive.type}
            value={formState.primitive.value}
            onSelected={(selected) =>
              setFormState((state) => {
                if (state.type === 'PRIMITIVE') {
                  return {
                    ...state,
                    primitive: { ...state.primitive, value: selected }
                  };
                }

                return state;
              })
            }>
            {formState.primitive.type === 'date'
              ? (value) => {
                  const date = value ? new Date(value) : '';
                  return (
                    <Form.Control
                      value={
                        date
                          ? `${date.getFullYear()}-${zeroStartSingleDigit(
                              date.getMonth() + 1
                            )}-${zeroStartSingleDigit(date.getDate())}`
                          : ''
                      }
                      type={formState.primitive.type}
                      onChange={(e) =>
                        setFormState((state) => {
                          if (state.type === 'PRIMITIVE') {
                            const [year, month, date] = e.target.value.split('-');
                            return {
                              ...state,
                              primitive: {
                                ...state.primitive,
                                value: new Date(
                                  Number(year),
                                  Number(removeLeadingZeroes(month)) - 1,
                                  Number(removeLeadingZeroes(date))
                                ).toISOString()
                              }
                            } as EditableComponentProperties;
                          }

                          return state;
                        })
                      }
                    />
                  );
                }
              : (value) => (
                  <Form.Control
                    as={formState.primitive.type === 'text' ? 'textarea' : undefined}
                    rows={5}
                    value={value}
                    type={formState.primitive.type}
                    onChange={(e) =>
                      setFormState((state) => {
                        if (state.type === 'PRIMITIVE') {
                          return {
                            ...state,
                            primitive: { ...state.primitive, value: e.target.value }
                          } as EditableComponentProperties;
                        }

                        return state;
                      })
                    }
                  />
                )}
          </UserPropertySelector>
        )}
        <Form.Text>Enter the value for the field</Form.Text>
      </Form.Group>
      <Form.Group className="mb-3 col">
        <Form.Label>Primitive description</Form.Label>
        <Form.Control
          value={formState.description ?? ''}
          type="text"
          onChange={(e) =>
            setFormState((state) => ({
              ...state,
              description: e.target.value
            }))
          }
        />
        <Form.Text>Enter the optional description for the field</Form.Text>
      </Form.Group>
    </>
  );
}

function zeroStartSingleDigit(text: string | number): string {
  return (typeof text === 'number' && text < 10) || (typeof text === 'string' && text.length < 2)
    ? `0${text}`
    : String(text);
}

function removeLeadingZeroes(text: string): string {
  return text.startsWith('0') ? text[1] : text;
}
