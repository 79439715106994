import type { Dispatch, SetStateAction } from 'react';
import Form from 'react-bootstrap/Form';

import type {
  EditableComponentProperties,
  HeadingComponentProperties,
  LayoutComponentProperties,
  PaddingPropertyType,
  ProfileProperty
} from '@felixcolaci/cic-profile-page-components';
import { defaultPadding, PaddingPropertyTypes } from '@felixcolaci/cic-profile-page-components';

import type { TooltipProviderChildrenProps } from '../../../../../shared/tooltipProvider';
import { TooltipProvider } from '../../../../../shared/tooltipProvider';

import { PaddingIcon } from './paddingIcon.component';

import styles from './paddingComponentEditorItem.module.scss';

interface PaddingComponentEditorItemProps {
  formState:
    | HeadingComponentProperties<string | ProfileProperty>
    | LayoutComponentProperties<Array<EditableComponentProperties>>;
  setFormState: Dispatch<SetStateAction<EditableComponentProperties>>;
}

export function PaddingComponentEditorItem({
  formState,
  setFormState
}: PaddingComponentEditorItemProps) {
  return (
    <Form.Group className="mb-3 col">
      <Form.Label>Padding</Form.Label>
      <div className={styles.controlsContainer}>
        {PaddingPropertyTypes.map((direction) => (
          <PaddingItem
            key={direction}
            direction={direction}
            formState={formState}
            setFormState={setFormState}
          />
        ))}
      </div>
      <Form.Text>
        Enter the padding for the component (Values are in pixels with a default value of 0px)
      </Form.Text>
    </Form.Group>
  );
}

interface PaddingItemProps extends PaddingComponentEditorItemProps {
  direction: PaddingPropertyType;
}

function PaddingItem({ direction, formState, setFormState }: PaddingItemProps) {
  return (
    <TooltipProvider label={`Padding ${direction}`}>
      {({ ref, showTooltip, hideTooltip }: TooltipProviderChildrenProps<HTMLDivElement>) => (
        <div key={direction} className={styles.controlContainer} ref={ref}>
          <PaddingIcon direction={direction} />
          <Form.Control
            value={formState.padding?.[direction] ?? ''}
            type="number"
            onChange={(e) =>
              setFormState((state) => {
                if (state.type === 'HEADING' || state.type === 'LAYOUT') {
                  const value = Number(e.target.value);

                  if (value < 0) {
                    return state;
                  }

                  return {
                    ...state,
                    padding: {
                      ...(state.padding ?? defaultPadding()),
                      [direction]: value
                    }
                  };
                }

                return state;
              })
            }
            onMouseLeave={hideTooltip}
            onMouseOver={showTooltip}
          />
        </div>
      )}
    </TooltipProvider>
  );
}
