import { nanoid } from 'nanoid';

import type { PaddingProperty, PlacableComponent } from '../types';

export interface HeadingComponentProperties<Value = string> extends PlacableComponent<'HEADING'> {
  level?: 1 | 2 | 3 | 4 | 5 | 6;
  padding?: PaddingProperty;
  value: Value;
}

interface HeadingProps extends HeadingComponentProperties {
  className?: string;
}

export function Heading({ className, id, level, padding, value }: HeadingProps) {
  const style = {
    paddingTop: padding?.top,
    paddingRight: padding?.right,
    paddingBottom: padding?.bottom,
    paddingLeft: padding?.left
  };

  switch (level) {
    case 1:
      return (
        <h1 className={`m-0 ${className}`} id={id} style={style}>
          {value}
        </h1>
      );
    case 2:
      return (
        <h2 className={`m-0 ${className}`} id={id} style={style}>
          {value}
        </h2>
      );
    case 3:
      return (
        <h3 className={`m-0 ${className}`} id={id} style={style}>
          {value}
        </h3>
      );
    case 4:
      return (
        <h4 className={`m-0 ${className}`} id={id} style={style}>
          {value}
        </h4>
      );
    case 5:
      return (
        <h5 className={`m-0 ${className}`} id={id} style={style}>
          {value}
        </h5>
      );
    case 6:
      return (
        <h6 className={`m-0 ${className}`} id={id} style={style}>
          {value}
        </h6>
      );
    default:
      return null;
  }
}

export function headingComponentPropertiesFactory(): HeadingComponentProperties {
  return {
    id: nanoid(10),
    padding: {
      top: 0,
      right: 0,
      bottom: 0,
      left: 0
    },
    type: 'HEADING',
    level: 1,
    value: 'New Heading'
  };
}
