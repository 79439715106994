import { CallToActionComponentProperties } from './callToAction';
import type { HeadingComponentProperties } from './heading';
import type { LayoutComponentProperties } from './layout';
import type { PrimitiveComponentProperties } from './primitive';
import type { SelectComponentProperties } from './select';

export const ComponentTypes = [
  'CALL_TO_ACTION',
  'LAYOUT',
  'HEADING',
  'PRIMITIVE',
  'SELECT'
] as const;

export type ComponentType = typeof ComponentTypes[number];

export const NonPrimitiveComponentTypes = ComponentTypes.filter(
  (type): type is Exclude<ComponentType, 'PRIMITIVE'> => type !== 'PRIMITIVE'
);

export const PaddingPropertyTypes = ['top', 'right', 'bottom', 'left'] as const;

export type PaddingPropertyType = typeof PaddingPropertyTypes[number];

export type PaddingProperty = {
  [key in PaddingPropertyType]: number;
};

export interface PlacableComponent<Type extends ComponentType> {
  id: string;
  type: Type;
}

export type ProfileProperty = {
  userProperty: string;
};

export type EditableComponentProperties =
  | CallToActionComponentProperties
  | HeadingComponentProperties<string | ProfileProperty>
  | PrimitiveComponentProperties<ProfileProperty>
  | LayoutComponentProperties<Array<EditableComponentProperties>>
  | SelectComponentProperties<string | ProfileProperty>;

export type PlacableComponentProperties =
  | CallToActionComponentProperties
  | HeadingComponentProperties
  | PrimitiveComponentProperties
  | LayoutComponentProperties
  | SelectComponentProperties;
