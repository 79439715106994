import _ from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Form, FormControl, InputGroup, ListGroup, ListGroupItem } from 'react-bootstrap';
import { useProfileStore } from '../../../stores/profile.store';
import styles from './searchUser.module.scss';

interface SearchResult {
  id: string;
  email: string;
}

export function SearchUser() {
  const [state, setState] = useState<Array<SearchResult>>([]);
  const [formState, setFormState] = useState<string>('');
  const searchUserAsync = useProfileStore((store) => store.searchUserAsync);
  const getProfileAsync = useProfileStore((store) => store.getProfileAsync);

  function resetState() {
    _.debounce(setState, 300)([]);
  }

  // search
  const search = useCallback(() => {
    searchUserAsync(formState).then((data) => {
      setState(data);
    });
  }, [setState, formState, searchUserAsync]);

  const debouncedSearch = useMemo(() => {
    return _.debounce(search, 400);
  }, [search]);

  // select a user
  function selectUser(id: string) {
    getProfileAsync(id)
      .then(() => {
        resetState();
        setFormState('');
      })
      .catch((error) => console.log(error));
  }

  // effects
  useEffect(() => {
    return () => {
      debouncedSearch.cancel();
    };
  }, [debouncedSearch]);

  useEffect(() => {
    debouncedSearch();
  }, [formState, debouncedSearch]);

  return (
    <Form className={styles.searchBar}>
      <InputGroup>
        <InputGroup.Text id="basic-addon1" className={styles.searchIcon}>
          <i className="bi bi-search"></i>
        </InputGroup.Text>
        <FormControl
          value={formState}
          onChange={(e) => setFormState(e.target.value)}
          onBlur={resetState}
          onFocus={resetState}
          type="text"
          className={styles.searchHover}
          name=""
          placeholder="search..."
        />
      </InputGroup>
      <ListGroup className={styles.searchResults}>
        {state.map((item) => (
          <ListGroupItem
            key={item.id}
            className={styles.searchResultItem}
            onClick={() => selectUser(item.id)}>
            {item.email}
          </ListGroupItem>
        ))}
      </ListGroup>
    </Form>
  );
}
