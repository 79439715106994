import type { Dispatch, FormEvent, SetStateAction } from 'react';
import { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';

import type { EditableComponentProperties } from '@felixcolaci/cic-profile-page-components';
import {
  bootstrapComponent,
  NonPrimitiveComponentTypes,
  PrimitiveComponentTypes,
  titleCase
} from '@felixcolaci/cic-profile-page-components';

import { useConfigStore } from '../../../../../stores/config.store';
import { useEditorStore } from '../../../../../stores/editor.store';

interface AddComponentFormElements extends HTMLFormControlsCollection {
  type: HTMLSelectElement;
}

interface AddComponentForm extends HTMLFormElement {
  readonly elements: AddComponentFormElements;
}

interface AddComponentEditorItemProps {
  formState: EditableComponentProperties;
  setFormState: Dispatch<SetStateAction<EditableComponentProperties>>;
}

export function AddComponentEditorItem({ formState, setFormState }: AddComponentEditorItemProps) {
  const editorClosed = useEditorStore(({ opened }) => !opened);
  const [appendedElementParent, setAppendedElementParent] = useState<HTMLElement | null>(null);
  useEffect(() => {
    if (editorClosed && appendedElementParent) {
      const lastChild = appendedElementParent.children[appendedElementParent.children.length - 1];

      lastChild.scrollIntoView({
        behavior: 'smooth',
        block: 'end'
      });

      (lastChild as HTMLElement).focus();

      setAppendedElementParent(null);
    }
  }, [editorClosed, appendedElementParent]);

  const appendComponent = useConfigStore(({ appendComponent }) => appendComponent);

  const closeEditor = useEditorStore(({ closeEditor }) => closeEditor);

  function addComponent(e: FormEvent<AddComponentForm>) {
    e.preventDefault();

    const value = e.currentTarget.elements.type.value;

    const componentToAppend = bootstrapComponent(value);

    appendComponent(componentToAppend, formState.id);

    setFormState((state) =>
      state.type === 'LAYOUT' ? { ...state, content: [...state.content, componentToAppend] } : state
    );

    closeEditor();

    setAppendedElementParent(document.getElementById(formState.id));
  }

  return (
    <Form onSubmit={addComponent}>
      <Form.Label>Add Component</Form.Label>
      <InputGroup>
        <Form.Select name="type">
          <optgroup label="Primitive Types">
            {PrimitiveComponentTypes.map((type) => (
              <option key={type} value={type}>
                {titleCase(type)}
              </option>
            ))}
          </optgroup>
          {NonPrimitiveComponentTypes.map((type) => (
            <option key={type} value={type}>
              {titleCase(type)}
            </option>
          ))}
        </Form.Select>
        <Button variant="primary" type="submit">
          Add
        </Button>
      </InputGroup>
      <Form.Text>Add a new componetn to your layout.</Form.Text>
    </Form>
  );
}
