import type { EditableComponentProperties } from '@felixcolaci/cic-profile-page-components';
import { titleCase } from '@felixcolaci/cic-profile-page-components';

import type { TooltipProviderChildrenProps } from '../../../../../shared/tooltipProvider';
import { TooltipProvider } from '../../../../../shared/tooltipProvider';
import { useConfigStore } from '../../../../../stores/config.store';

import styles from './nonLayoutNavigationItem.module.scss';

interface NonLayoutNavigatorItemProps {
  activeComponentId: string;
  className?: string;
  component: EditableComponentProperties;
  hoveredComponentId: string;
  indentationMultiple: number;
  getIndentationPadding(indentationMultiple: number): string;
  resetHoveredComponentId(): void;
  setHoveredComponentId(componentId: string): () => void;
  updateActiveComponent(component: EditableComponentProperties): () => void;
}

export function NonLayoutNavigatorItem({
  activeComponentId,
  className,
  component,
  hoveredComponentId,
  indentationMultiple,
  getIndentationPadding,
  resetHoveredComponentId,
  setHoveredComponentId,
  updateActiveComponent
}: NonLayoutNavigatorItemProps) {
  const removeComponent = useConfigStore(
    ({ removeComponent }) =>
      (componentId: string) =>
      () =>
        removeComponent(componentId)
  );

  return (
    <div
      className={`${className} ${component.id === activeComponentId ? styles.activeAction : ``}`}
      style={{ padding: getIndentationPadding(indentationMultiple * 1.125) }}>
      <button
        className={component.id === hoveredComponentId ? styles.hoveredAction : undefined}
        type="button"
        onClick={updateActiveComponent(component)}
        onMouseLeave={resetHoveredComponentId}
        onMouseOver={setHoveredComponentId(component.id)}>
        {titleCase(component.type)}
      </button>
      <TooltipProvider label={`Delete ${titleCase(component.type)}`}>
        {({
          label,
          ref,
          hideTooltip,
          showTooltip
        }: TooltipProviderChildrenProps<HTMLButtonElement>) => (
          <button
            aria-label={label}
            ref={ref}
            type="button"
            onClick={removeComponent(component.id)}
            onMouseLeave={hideTooltip}
            onMouseOver={showTooltip}>
            <i className="bi bi-trash" />
          </button>
        )}
      </TooltipProvider>
    </div>
  );
}
