import type { EditableComponentProperties } from '@felixcolaci/cic-profile-page-components';
import {
  Layout,
  ComponentHost,
  transformEditableToPlacable
} from '@felixcolaci/cic-profile-page-components';
import { useEffect } from 'react';

import { useComponentNavigatorStore } from '../../../../stores/componentNavigator.store';
import { useProfileStore } from '../../../../stores/profile.store';

import styles from './editorItem.module.scss';

interface EditorItemProps {
  component: EditableComponentProperties;
}

export function EditorItem({ component }: EditorItemProps) {
  const { activeComponentId, hoveredComponentId } = useComponentNavigatorStore(
    ({ activeComponentId, hoveredComponentId }) => ({
      activeComponentId,
      hoveredComponentId
    })
  );

  const { profile, profileSchema } = useProfileStore(({ profile, profileSchema }) => ({
    profile,
    profileSchema
  }));

  useEffect(() => {
    if (component.id === hoveredComponentId) {
      document
        .getElementById(hoveredComponentId)
        ?.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }, [component.id, hoveredComponentId]);

  const className = `${styles.editorItem} ${
    component.id === activeComponentId && styles.activeItem
  } ${component.id === hoveredComponentId && styles.hoveredItem}`;

  if (component.type === 'LAYOUT') {
    return (
      <Layout
        {...component}
        className={className}
        content={
          <>
            {component.content.map((component) => (
              <EditorItem key={component.id} component={component} />
            ))}
          </>
        }
      />
    );
  }

  if (profile && profileSchema) {
    return (
      <ComponentHost
        className={className}
        component={transformEditableToPlacable(component, profile, profileSchema)}
      />
    );
  }

  return null;
}
