import { nanoid } from 'nanoid';
import Form from 'react-bootstrap/Form';

import type { PlacableComponent } from '../types';

import styles from '../styles/components.module.scss';

export interface SelectOptionProps {
  value: string;
  label: string;
}

export interface SelectComponentProperties<Value = never> extends PlacableComponent<'SELECT'> {
  description?: string;
  disabled?: boolean;
  label: string;
  name?: string;
  readOnly: boolean;
  value: string | Value;
  options: Array<SelectOptionProps>;
}

interface SelectProps extends SelectComponentProperties {
  className?: string;
}

export function Select({
  className,
  description,
  disabled,
  id,
  label,
  name,
  readOnly,
  value,
  options
}: SelectProps) {
  return (
    <Form.Group className={`${styles.formGroup} ${className}`} id={id}>
      <Form.Label className={styles.formLabel}>
        {label}
        {readOnly && <small className="text-muted">Read only</small>}
      </Form.Label>
      <Form.Select defaultValue={value} disabled={disabled || readOnly} name={name}>
        {options.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </Form.Select>
      {description && <Form.Text>{description}</Form.Text>}
    </Form.Group>
  );
}

export function selectComponentPropertiesFactory(): SelectComponentProperties {
  return {
    id: nanoid(10),
    label: 'New Select Component',
    options: [],
    readOnly: false,
    type: 'SELECT',
    value: ''
  };
}
