import type { Dispatch, SetStateAction } from 'react';
import { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';

import { TooltipProvider, TooltipProviderChildrenProps } from '../../../shared/tooltipProvider';

import { useConfigStore } from '../../../stores/config.store';

import { ComponentTree } from './componentTree';

import styles from './componentNavigator.module.scss';

interface ComponentNavigatorProps {
  setComponentNavigatorWidth: Dispatch<SetStateAction<number>>;
}

export function ComponentNavigator({ setComponentNavigatorWidth }: ComponentNavigatorProps) {
  const config = useConfigStore((state) => state.config);

  const { showNavigator, setComponentNavigator, toggleShowNavigator } = useComponentNavigator(
    setComponentNavigatorWidth
  );

  const componentNavigatorToggleLabel = showNavigator ? 'Close navigator' : 'Show navigator';

  return (
    <div className={styles.container}>
      {showNavigator && (
        <div className={`bg-dark ${styles.componentNavigator}`} ref={setComponentNavigator}>
          <div>
            <h4 className={styles.text}>Component Navigator</h4>
            <p className={styles.text}>Select a component from here to edit it</p>
          </div>
          <div>
            <ComponentTree className={styles.firstComponent} component={config} />
          </div>
        </div>
      )}
      <TooltipProvider label={componentNavigatorToggleLabel} placement="right">
        {({ ref, hideTooltip, showTooltip }: TooltipProviderChildrenProps<HTMLButtonElement>) => (
          <Button
            aria-checked={showNavigator}
            aria-label={componentNavigatorToggleLabel}
            className={styles.componentNavigatorToggle}
            ref={ref}
            role="switch"
            size="sm"
            variant="outline-dark"
            onClick={toggleShowNavigator}
            onMouseLeave={hideTooltip}
            onMouseOver={showTooltip}>
            <i className={`bi bi-${showNavigator ? 'x-lg' : 'arrow-right'}`} />
          </Button>
        )}
      </TooltipProvider>
    </div>
  );
}

function useComponentNavigator(setComponentNavigatorWidth: Dispatch<SetStateAction<number>>) {
  const [showNavigator, setShowNavigator] = useState<boolean>(true);

  function toggleShowNavigator() {
    setShowNavigator((show) => !show);
  }

  useEffect(() => {
    if (!showNavigator) {
      setComponentNavigatorWidth(0);
    }
  }, [showNavigator, setComponentNavigatorWidth]);

  const [componentNavigator, setComponentNavigator] = useState<HTMLDivElement | null>(null);

  useEffect(() => {
    function onComponentNavigatorSizeChanged(element: HTMLDivElement) {
      return () => {
        setComponentNavigatorWidth(element.getBoundingClientRect().width);
      };
    }

    if (componentNavigator) {
      setComponentNavigatorWidth(componentNavigator.getBoundingClientRect().width);
      const element = componentNavigator;
      const resizeCallback = onComponentNavigatorSizeChanged(element);

      element.addEventListener('resize', resizeCallback);
      return () => {
        element.removeEventListener('resize', resizeCallback);
      };
    }
  }, [componentNavigator, setComponentNavigatorWidth]);

  return { showNavigator, setComponentNavigator, toggleShowNavigator };
}
