import type { Dispatch, SetStateAction } from 'react';
import Form from 'react-bootstrap/Form';

import type {
  HeadingComponentProperties,
  EditableComponentProperties,
  ProfileProperty
} from '@felixcolaci/cic-profile-page-components';

import { UserPropertySelector } from '../userPropertySelector';

interface HeadingEditorItemsProps {
  formState: HeadingComponentProperties<string | ProfileProperty>;
  setFormState: Dispatch<SetStateAction<EditableComponentProperties>>;
}

export function HeadingEditorItems({ formState, setFormState }: HeadingEditorItemsProps) {
  return (
    <>
      <Form.Group className="mb-3 col">
        <Form.Label>Heading level</Form.Label>
        <Form.Control
          value={formState.level}
          min="1"
          max="6"
          type="number"
          onChange={(e) =>
            setFormState((state) => {
              const value = Number(e.target.value);

              if (value > 6 || value < 1) {
                return state;
              }

              if (state.type === 'HEADING') {
                return {
                  ...state,
                  level: Number(e.target.value)
                } as EditableComponentProperties;
              }

              return state;
            })
          }
        />
        <Form.Text>Select the heading level</Form.Text>
      </Form.Group>
      <Form.Group className="mb-3 col">
        <Form.Label>Heading content</Form.Label>
        <UserPropertySelector
          fieldType="string"
          value={formState.value}
          onSelected={(selected) => setFormState((state) => ({ ...state, value: selected }))}>
          {(value) => (
            <Form.Control
              value={value}
              type="text"
              onChange={(e) => setFormState((state) => ({ ...state, value: e.target.value }))}
            />
          )}
        </UserPropertySelector>
        <Form.Text>Enter the text for the heading</Form.Text>
      </Form.Group>
    </>
  );
}
