import { produce } from 'immer';
import { nanoid } from 'nanoid';
import create from 'zustand';
import { devtools } from 'zustand/middleware';

import type {
  AlignItemsType,
  DirectionType,
  EditableComponentProperties,
  JustifyContentType,
  LayoutComponentProperties
} from '@felixcolaci/cic-profile-page-components';
import { layoutComponentPropertiesFactory } from '@felixcolaci/cic-profile-page-components';

interface ConfigState {
  config: LayoutComponentProperties<Array<EditableComponentProperties>>;
  hasAddedComponent: boolean;
  hasChanges: boolean;
  appendComponent(componentToAppend: EditableComponentProperties, parentId: string): void;
  persistChangesAsync(): Promise<void>;
  refreshFromServerAsync(): Promise<void>;
  removeComponent(componentId: string): void;
  updateComponent(updatedComponent: EditableComponentProperties): void;
}

const CONFIG_URL = 'https://ko3t4vcvqb.execute-api.us-east-1.amazonaws.com/config';

type ComponentToAppendParent = {
  content: Array<EditableComponentProperties>;
  id: string;
};

type ComponentToRemoveParent = {
  content: Array<EditableComponentProperties>;
};

type ComponentToUpdateParent = {
  content: Array<EditableComponentProperties>;
};

export const useConfigStore = create<ConfigState>()(
  devtools((set, get) => ({
    config: {
      alignItems: 'stretch' as AlignItemsType,
      content: [] as Array<EditableComponentProperties>,
      direction: 'row' as DirectionType,
      height: '100%',
      id: nanoid(10),
      justifyContent: 'center' as JustifyContentType,
      gap: '0',
      type: 'LAYOUT',
      width: '100%'
    },
    hasAddedComponent: false,
    hasChanges: false,
    hoveredComponentId: '',
    appendComponent(componentToAppend, parentId) {
      set(
        produce((state: ConfigState) => {
          for (let itemIndex = 0; itemIndex < state.config.content.length; itemIndex++) {
            if (
              state.config.content[itemIndex].type === 'LAYOUT' &&
              append(
                componentToAppend,
                state.config.content[itemIndex] as ComponentToAppendParent,
                parentId
              )
            ) {
              state.hasAddedComponent = true;
              state.hasChanges = true;
              break;
            }
          }
        })
      );
    },
    async persistChangesAsync() {
      const state = get();
      const body = JSON.stringify(state.config);

      fetch(CONFIG_URL, {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json'
        },
        body
      }).then(() => {
        set({ ...state, hasChanges: false });
      });
    },
    async refreshFromServerAsync() {
      fetch(CONFIG_URL)
        .then((response) => response.json())
        .then((content) => {
          set({ config: content });
        })
        .catch(console.error);
    },
    removeComponent(componentId) {
      set(
        produce((state: ConfigState) => {
          if (componentId !== state.config.id) {
            if (remove(componentId, state.config)) {
              state.hasChanges = true;
            }
          }

          state.hasAddedComponent = false;
        })
      );
    },
    updateComponent(updatedComponent) {
      set(
        produce((state: ConfigState) => {
          if (updatedComponent.id === state.config.id && updatedComponent.type === 'LAYOUT') {
            state.config = updatedComponent;
            state.hasChanges = true;
          } else if (update(updatedComponent, state.config)) {
            state.hasChanges = true;
          }

          state.hasAddedComponent = false;
        })
      );
    }
  }))
);

function append(
  componentToAppend: EditableComponentProperties,
  parent: ComponentToAppendParent,
  parentId: string
) {
  if (parent.id === parentId) {
    if (
      parent.content.length &&
      parent.content[parent.content.length - 1].type === 'LAYOUT' &&
      componentToAppend.type !== 'LAYOUT'
    ) {
      parent.content.push({
        ...layoutComponentPropertiesFactory(),
        content: [componentToAppend]
      });
    } else {
      parent.content.push(componentToAppend);
    }
    return true;
  } else {
    for (let itemIndex = 0; itemIndex < parent.content.length; itemIndex++) {
      if (
        parent.content[itemIndex].type === 'LAYOUT' &&
        append(componentToAppend, parent.content[itemIndex] as ComponentToAppendParent, parentId)
      ) {
        return true;
      }
    }
  }
}

function remove(componentId: string, parent: ComponentToRemoveParent) {
  for (let itemIndex = 0; itemIndex < parent.content.length; itemIndex++) {
    if (componentId === parent.content[itemIndex].id) {
      parent.content.splice(itemIndex, 1);
      return true;
    } else if (
      parent.content[itemIndex].type === 'LAYOUT' &&
      remove(componentId, parent.content[itemIndex] as ComponentToRemoveParent)
    ) {
      return true;
    }
  }
}

function update(componentToUpdate: EditableComponentProperties, parent: ComponentToUpdateParent) {
  for (let itemIndex = 0; itemIndex < parent.content.length; itemIndex++) {
    if (componentToUpdate.id === parent.content[itemIndex].id) {
      parent.content.splice(itemIndex, 1, componentToUpdate);
      return true;
    } else if (
      parent.content[itemIndex].type === 'LAYOUT' &&
      update(componentToUpdate, parent.content[itemIndex] as ComponentToUpdateParent)
    ) {
      return true;
    }
  }
}
