import { Link } from 'react-router-dom';
import Navbar from 'react-bootstrap/Navbar';

import { NavBarAuthButton } from './auth';

import { SearchUser } from './search';

import { useEffect, useRef, useState } from 'react';

import styles from './header.module.scss';

export function Header() {
  const initialTimestamp = useRef<number>(Date.now());
  const [, setToggle] = useState<boolean>(false);

  useEffect(() => {
    const interval = setInterval(() => {
      setToggle((value) => !value);
    }, 10000);
    return () => clearInterval(interval);
  }, []);

  return (
    <Navbar className={styles.customNav} bg="dark" expand="lg">
      <div className={styles.customNavContainer}>
        <Navbar.Brand as={Link} className={styles.navbarBrand} to="/">
          <Logo />
          <div className={styles.spacer} />
          CIC Custom Profile Page
        </Navbar.Brand>
        <SearchUser />
        <div>
          <small>{`Last updated ${fromNowTo(initialTimestamp.current)}`}</small>
          <small className={styles.noBorder}>
            <a
              className="btn btn-outline-light btn-sm"
              href="https://profile.ciam.guru"
              target="blank">
              <span>Open live profile</span>
            </a>
          </small>

          <NavBarAuthButton />
        </div>
      </div>
    </Navbar>
  );
}

function Logo() {
  return (
    <svg className="sc-gJmMlv bTEWmQ QuantumLogo-svg logo" viewBox="0 0 29 32">
      <g transform="translate(-100.000000, -88.000000)" fill="currentColor">
        <g transform="translate(100.000000, 88.000000)">
          <path d="M22.9616476,25.864 L19.715652,15.9851429 L28.2126476,9.88133333 L17.7094918,9.88133333 L14.4633035,0.00285714286 L14.4623403,0.00019047619 L24.9670372,0.00019047619 L28.2139961,9.88038095 L28.2141888,9.88019048 L28.2168857,9.87866667 C30.1024528,15.6104762 28.160442,22.1297143 22.9616476,25.864 L22.9616476,25.864 L22.9616476,25.864 Z M5.96418875,25.864 L5.96149178,25.8659048 L14.4606065,31.9712381 L22.9616476,25.8641905 L14.4633035,19.7586667 L5.96418875,25.864 L5.96418875,25.864 L5.96418875,25.864 Z M0.711069704,9.87885714 L0.711069704,9.87885714 C-1.27389999,15.9209524 1.03027533,22.3232381 5.96245499,25.8651429 L5.96322555,25.8620952 L9.2096065,15.9838095 L0.714537237,9.88114286 L11.2151888,9.88114286 L14.4615697,0.00266666667 L14.4623403,0 L3.95706538,0 L0.711069704,9.87885714 L0.711069704,9.87885714 L0.711069704,9.87885714 Z"></path>
        </g>
      </g>
    </svg>
  );
}

function fromNowTo(date: number) {
  const now = Date.now();
  const formatter = new Intl.RelativeTimeFormat(undefined, { numeric: 'auto' });

  const SECOND = 1000;
  const MINUTE = 60 * SECOND;
  const HOUR = 60 * MINUTE;
  const DAY = 24 * HOUR;
  const WEEK = 7 * DAY;
  const MONTH = 30 * DAY;
  const YEAR = 365 * DAY;

  const intervals: Array<{ limit: number; divisor: number; unit: Intl.RelativeTimeFormatUnit }> = [
    { limit: YEAR, divisor: YEAR, unit: 'year' },
    { limit: MONTH, divisor: MONTH, unit: 'month' },
    { limit: WEEK, divisor: WEEK, unit: 'week' },
    { limit: DAY, divisor: DAY, unit: 'day' },
    { limit: HOUR, divisor: HOUR, unit: 'hour' },
    { limit: MINUTE, divisor: MINUTE, unit: 'minute' },
    { limit: 0, divisor: SECOND, unit: 'seconds' }
  ];

  const diff = now - date;

  const millisecondsElapsed = Math.abs(diff);

  for (const interval of intervals) {
    if (millisecondsElapsed >= interval.limit) {
      const numberOfUnitsForInterval = Math.round(millisecondsElapsed / interval.divisor);
      const isFuture = diff < 0;
      return formatter.format(
        isFuture ? numberOfUnitsForInterval : -numberOfUnitsForInterval,
        interval.unit
      );
    }
  }
}
