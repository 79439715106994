import { useEffect } from 'react';
import Card from 'react-bootstrap/Card';
import Offcanvas from 'react-bootstrap/Offcanvas';
import OffcanvasBody from 'react-bootstrap/OffcanvasBody';
import OffcanvasHeader from 'react-bootstrap/OffcanvasHeader';
import OffcanvasTitle from 'react-bootstrap/OffcanvasTitle';

import { useComponentNavigatorStore } from '../../../stores/componentNavigator.store';
import { useConfigStore } from '../../../stores/config.store';
import { useEditorStore } from '../../../stores/editor.store';

import { ComponentEditor } from './componentEditor';

import styles from './editorControls.module.scss';

export function EditorControls() {
  const resetActiveComponentId = useComponentNavigatorStore(
    ({ resetActiveComponentId }) => resetActiveComponentId
  );

  const { hasAddedComponent, hasChanges, persistChangesAsync } = useConfigStore(
    ({ hasAddedComponent, hasChanges, persistChangesAsync }) => ({
      hasAddedComponent,
      hasChanges,
      persistChangesAsync
    })
  );

  useEffect(() => {
    if (hasChanges) {
      persistChangesAsync().catch(console.error);
    }
  }, [hasChanges, persistChangesAsync]);

  const { editorComponent, opened, closeEditor } = useEditorStore(
    ({ editorComponent, opened, closeEditor }) => ({
      editorComponent,
      opened,
      closeEditor
    })
  );

  useEffect(() => {
    if (!opened) {
      resetActiveComponentId();
    }
  }, [opened, resetActiveComponentId]);

  return (
    <div className={styles.editorControls}>
      <Offcanvas
        placement="end"
        restoreFocus={!hasAddedComponent}
        scroll={true}
        show={opened}
        onHide={closeEditor}>
        <OffcanvasHeader closeButton>
          <OffcanvasTitle>
            <span>Edit Component</span>
          </OffcanvasTitle>
        </OffcanvasHeader>
        <OffcanvasBody>
          {editorComponent && (
            <Card>
              <Card.Body>
                <ComponentEditor component={editorComponent} />
              </Card.Body>
            </Card>
          )}
        </OffcanvasBody>
      </Offcanvas>
    </div>
  );
}
