import type { FormEvent } from 'react';
import { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';

import type { EditableComponentProperties } from '@felixcolaci/cic-profile-page-components';

import { CallToActionEditorItems } from './callToActionEditorItems';
import { HeadingEditorItems } from './headingEditorItems';
import { LayoutEditorItems } from './layoutEditorItems';
import { PrimitiveEditorItems } from './primitiveEditorItems';
import { SelectEditorItems } from './selectEditorItems';

import { useConfigStore } from '../../../../stores/config.store';
import { useEditorStore } from '../../../../stores/editor.store';

import styles from './componentEditor.module.scss';
import { ComponentTypeEditorItem } from './componentTypeEditorItem';
import { AddComponentEditorItem } from './addComponentEditorItem';
import { PaddingComponentEditorItem } from './paddingComponentEditorItem';

interface ComponentEditorProps {
  component: EditableComponentProperties;
}

export function ComponentEditor({ component }: ComponentEditorProps) {
  const [formState, setFormState] = useState<EditableComponentProperties>(component);
  useEffect(() => {
    setFormState(component);
  }, [component]);

  const { configId, removeComponent, updateComponent } = useConfigStore(
    ({ config, updateComponent, removeComponent }) => ({
      configId: config.id,
      updateComponent,
      removeComponent
    })
  );

  const closeEditor = useEditorStore(({ closeEditor }) => closeEditor);

  function deleteComponent(e: FormEvent) {
    e.preventDefault();

    removeComponent(formState.id);

    closeEditor();
  }

  function editComponent(e: FormEvent) {
    e.preventDefault();

    updateComponent(formState);

    closeEditor();
  }

  return (
    <div>
      <Form onSubmit={editComponent}>
        <ComponentTypeEditorItem formState={formState} setFormState={setFormState} />
        {formState.type === 'CALL_TO_ACTION' && (
          <CallToActionEditorItems formState={formState} setFormState={setFormState} />
        )}
        {formState.type === 'HEADING' && (
          <HeadingEditorItems formState={formState} setFormState={setFormState} />
        )}
        {formState.type === 'LAYOUT' && (
          <LayoutEditorItems formState={formState} setFormState={setFormState} />
        )}
        {formState.type === 'PRIMITIVE' && (
          <PrimitiveEditorItems formState={formState} setFormState={setFormState} />
        )}
        {formState.type === 'SELECT' && (
          <SelectEditorItems formState={formState} setFormState={setFormState} />
        )}
        {(formState.type === 'HEADING' || formState.type === 'LAYOUT') && (
          <PaddingComponentEditorItem formState={formState} setFormState={setFormState} />
        )}
        {/* The user should not be able to delete the root layout */}
        {/* as this should always be the base of the editor */}
        {component.id !== configId && (
          <Button
            variant="outline-danger"
            style={{ marginRight: '1rem' }}
            type="button"
            onClick={deleteComponent}>
            Delete
          </Button>
        )}
        <Button variant="primary" type="submit">
          Update
        </Button>
      </Form>
      {/* Only show the add component item if this component was initially a layout component. */}
      {/* This is because when adding a new layout component we cannot add child components at the same time. */}
      {/* We must first add the layout component then add child components. */}
      {formState.type === 'LAYOUT' && component.type === formState.type && (
        <>
          <div className={styles.divider} />
          <AddComponentEditorItem formState={formState} setFormState={setFormState} />
        </>
      )}
    </div>
  );
}
