import type { Dispatch, SetStateAction } from 'react';
import Form from 'react-bootstrap/Form';

import type { EditableComponentProperties } from '@felixcolaci/cic-profile-page-components';
import {
  bootstrapComponent,
  NonPrimitiveComponentTypes,
  PrimitiveComponentTypes,
  titleCase
} from '@felixcolaci/cic-profile-page-components';

interface ComponentTypeEditorItemProps {
  formState: EditableComponentProperties;
  setFormState: Dispatch<SetStateAction<EditableComponentProperties>>;
}

export function ComponentTypeEditorItem({ formState, setFormState }: ComponentTypeEditorItemProps) {
  return (
    <Form.Group className="mb-3 col">
      <Form.Label>Component Type</Form.Label>
      <Form.Select
        value={formState.type === 'PRIMITIVE' ? formState.primitive.type : formState.type}
        onChange={(e) =>
          setFormState((state) => {
            if (
              NonPrimitiveComponentTypes.map(String).includes(e.target.value) ||
              PrimitiveComponentTypes.map(String).includes(e.target.value)
            ) {
              return {
                ...bootstrapComponent(e.target.value),
                id: formState.id
              };
            }

            return state;
          })
        }>
        {NonPrimitiveComponentTypes.map((type) => (
          <option key={type} value={type}>
            {titleCase(type)}
          </option>
        ))}
        {PrimitiveComponentTypes.map((type) => (
          <option key={type} value={type}>
            {titleCase(type)}
          </option>
        ))}
      </Form.Select>
      <Form.Text>Select a different component type.</Form.Text>
    </Form.Group>
  );
}
