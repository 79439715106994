import type { Dispatch, SetStateAction } from 'react';
import Form from 'react-bootstrap/Form';

import type {
  LayoutComponentProperties,
  EditableComponentProperties
} from '@felixcolaci/cic-profile-page-components';
import {
  AlignItemsTypes,
  DirectionTypes,
  JustifyContentTypes,
  titleCase
} from '@felixcolaci/cic-profile-page-components';

interface LayoutEditorItemsProps {
  formState: LayoutComponentProperties<Array<EditableComponentProperties>>;
  setFormState: Dispatch<SetStateAction<EditableComponentProperties>>;
}

export function LayoutEditorItems({ formState, setFormState }: LayoutEditorItemsProps) {
  return (
    <>
      <Form.Group className="mb-3 col">
        <Form.Label>Direction</Form.Label>
        <Form.Select
          value={formState.direction}
          onChange={(e) =>
            setFormState((state) => {
              return {
                ...state,
                direction: e.target.value
              } as EditableComponentProperties;
            })
          }>
          {DirectionTypes.map((property) => (
            <option key={property} value={property}>
              {titleCase(property)}
            </option>
          ))}
        </Form.Select>
        <Form.Text>Which direction should your layout have?</Form.Text>
      </Form.Group>
      <Form.Group className="mb-3 col">
        <Form.Label>Main axis alignment</Form.Label>
        <Form.Select
          value={formState.justifyContent}
          onChange={(e) =>
            setFormState((state) => {
              return {
                ...state,
                justifyContent: e.target.value
              } as EditableComponentProperties;
            })
          }>
          {JustifyContentTypes.map((property) => (
            <option key={property} value={property}>
              {titleCase(property)}
            </option>
          ))}
        </Form.Select>
        <Form.Text>{`How would your like your items positioned along the main axis (${
          formState.direction === 'row' ? 'horizontally' : 'vertically'
        })?`}</Form.Text>
      </Form.Group>
      <Form.Group className="mb-3 col">
        <Form.Label>Cross axis alignment</Form.Label>
        <Form.Select
          value={formState.alignItems}
          onChange={(e) =>
            setFormState((state) => {
              return {
                ...state,
                alignItems: e.target.value
              } as EditableComponentProperties;
            })
          }>
          {AlignItemsTypes.map((property) => (
            <option key={property} value={property}>
              {titleCase(property)}
            </option>
          ))}
        </Form.Select>
        <Form.Text>{`How would your like your items positioned along the cross axis (${
          formState.direction === 'row' ? 'vertically' : 'horizontally'
        })?`}</Form.Text>
      </Form.Group>
      <Form.Group className="mb-3 col">
        <Form.Label>Gap</Form.Label>
        <Form.Control
          value={formState.gap}
          onChange={(e) =>
            setFormState((state) => {
              return { ...state, gap: e.target.value } as EditableComponentProperties;
            })
          }
        />
        <Form.Text>
          How much space would you like between items in the layout? (Feel free to enter any valid
          css value)
        </Form.Text>
      </Form.Group>
      <Form.Group className="mb-3 col">
        <Form.Label>Width</Form.Label>
        <Form.Control
          value={formState.width}
          onChange={(e) =>
            setFormState((state) => {
              return { ...state, width: e.target.value } as EditableComponentProperties;
            })
          }
        />
        <Form.Text>
          How wide would you like your layout to be? (Feel free to enter any valid css value)
        </Form.Text>
      </Form.Group>
    </>
  );
}
