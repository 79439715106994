import type { EditableComponentProperties } from '@felixcolaci/cic-profile-page-components';

import { useComponentNavigatorStore } from '../../../../stores/componentNavigator.store';
import { useEditorStore } from '../../../../stores/editor.store';

import { LayoutNavigatorItem } from './layoutNavigatorItem';

import { NonLayoutNavigatorItem } from './nonLayoutNavigatorItem';

interface ComponentTreeProps {
  className?: string;
  component: EditableComponentProperties;
  indentationMultiple?: number;
}

export function ComponentTree({
  className,
  component,
  indentationMultiple = 1
}: ComponentTreeProps) {
  const {
    activeComponentId,
    hoveredComponentId,
    resetHoveredComponentId,
    setActiveComponentId,
    setHoveredComponentId
  } = useComponentNavigatorStore(
    ({
      activeComponentId,
      hoveredComponentId,
      resetHoveredComponentId,
      setActiveComponentId,
      setHoveredComponentId
    }) => ({
      activeComponentId,
      hoveredComponentId,
      resetHoveredComponentId,
      setActiveComponentId,
      setHoveredComponentId: (componentId: string) => () => setHoveredComponentId(componentId)
    })
  );

  function getIndentationPadding(indentationMultiple: number): string {
    return `0 calc(${indentationMultiple} * 0.5rem)`;
  }

  const setEditorComponent = useEditorStore((state) => state.setEditorComponent);

  function updateActiveComponent(component: EditableComponentProperties) {
    return () => {
      setActiveComponentId(component.id);
      setEditorComponent(component);
    };
  }

  return component.type === 'LAYOUT' ? (
    <LayoutNavigatorItem
      activeComponentId={activeComponentId}
      className={className}
      component={component}
      hoveredComponentId={hoveredComponentId}
      indentationMultiple={indentationMultiple}
      getIndentationPadding={getIndentationPadding}
      resetHoveredComponentId={resetHoveredComponentId}
      setHoveredComponentId={setHoveredComponentId}
      updateActiveComponent={updateActiveComponent}
    />
  ) : (
    <NonLayoutNavigatorItem
      activeComponentId={activeComponentId}
      className={className}
      component={component}
      hoveredComponentId={hoveredComponentId}
      indentationMultiple={indentationMultiple}
      getIndentationPadding={getIndentationPadding}
      resetHoveredComponentId={resetHoveredComponentId}
      setHoveredComponentId={setHoveredComponentId}
      updateActiveComponent={updateActiveComponent}
    />
  );
}
