import type { Dispatch, SetStateAction } from 'react';
import Form from 'react-bootstrap/Form';

import type {
  CallToActionButtonType,
  CallToActionVariantType,
  CallToActionComponentProperties,
  EditableComponentProperties
} from '@felixcolaci/cic-profile-page-components';
import {
  CallToActionButtonTypes,
  CallToActionVariantTypes,
  titleCase
} from '@felixcolaci/cic-profile-page-components';

interface CallToActionEditorItemsProps {
  formState: CallToActionComponentProperties;
  setFormState: Dispatch<SetStateAction<EditableComponentProperties>>;
}

export function CallToActionEditorItems({ formState, setFormState }: CallToActionEditorItemsProps) {
  return (
    <>
      <Form.Group className="mb-3 col">
        <Form.Label>Text</Form.Label>
        <Form.Control
          value={formState.text}
          type="text"
          onChange={(e) => setFormState((state) => ({ ...state, text: e.target.value }))}
        />
        <Form.Text>Enter the text for the call to action</Form.Text>
      </Form.Group>
      <Form.Group className="mb-3 col">
        <Form.Label>Type</Form.Label>
        <Form.Select
          value={formState.buttonType}
          onChange={(e) =>
            setFormState((state) =>
              isCallToActionButtonType(e.target.value)
                ? {
                    ...state,
                    buttonType: e.target.value
                  }
                : state
            )
          }>
          {CallToActionButtonTypes.map((type) => (
            <option key={type} value={type}>
              {titleCase(type)}
            </option>
          ))}
        </Form.Select>
        <Form.Text>Choose the call to action type</Form.Text>
      </Form.Group>
      <Form.Group className="mb-3 col">
        <Form.Label>Variant</Form.Label>
        <Form.Select
          value={formState.variant}
          onChange={(e) =>
            setFormState((state) =>
              isCallToActionVariantType(e.target.value)
                ? {
                    ...state,
                    variant: e.target.value
                  }
                : state
            )
          }>
          {CallToActionVariantTypes.map((type) => (
            <option key={type} value={type}>
              {titleCase(type)}
            </option>
          ))}
        </Form.Select>
        <Form.Text>Choose the call to action variant</Form.Text>
      </Form.Group>
    </>
  );
}

function isCallToActionButtonType(value: string): value is CallToActionButtonType {
  return (CallToActionButtonTypes as unknown as Array<string>).includes(value);
}

function isCallToActionVariantType(value: string): value is CallToActionVariantType {
  return (CallToActionVariantTypes as unknown as Array<string>).includes(value);
}
