import create from 'zustand';
import { devtools } from 'zustand/middleware';

import type { EditableComponentProperties } from '@felixcolaci/cic-profile-page-components';

interface EditorState {
  opened: boolean;
  editorComponent?: EditableComponentProperties;
  closeEditor(): void;
  setEditorComponent(editorComponent: EditableComponentProperties): void;
}

export const useEditorStore = create<EditorState>()(
  devtools((set) => ({
    opened: false,
    closeEditor() {
      set(() => {
        return { opened: false };
      });
    },
    setEditorComponent(editorComponent: EditableComponentProperties) {
      set(() => {
        return { editorComponent, opened: true };
      });
    }
  }))
);
