import type { RefObject, ReactNode } from 'react';
import { useRef, useState } from 'react';
import type { Placement } from 'react-bootstrap/types';
import Overlay from 'react-bootstrap/Overlay';
import Tooltip from 'react-bootstrap/Tooltip';

export interface TooltipProviderChildrenProps<RefType extends HTMLElement> {
  label: string;
  ref: RefObject<RefType>;
  hideTooltip(): void;
  showTooltip(): void;
}

interface TooltipProviderProps<RefType extends HTMLElement> {
  label: ReactNode;
  placement?: Placement;
  children(childrenProps: TooltipProviderChildrenProps<RefType>): ReactNode;
}

export function TooltipProvider<RefType extends HTMLElement>({
  label,
  placement,
  children
}: TooltipProviderProps<RefType>) {
  const ref = useRef<RefType>(null);

  const [show, setShow] = useState<boolean>(false);

  function toggleTooltip(value: boolean) {
    return () => {
      setShow(value);
    };
  }

  return (
    <>
      {children({
        label: label?.toString() ?? ``,
        ref,
        hideTooltip: toggleTooltip(false),
        showTooltip: toggleTooltip(true)
      })}
      <Overlay placement={placement ?? 'top'} show={show} target={ref.current}>
        {(props) => <Tooltip {...props}>{label}</Tooltip>}
      </Overlay>
    </>
  );
}
