import create from 'zustand';
import { devtools } from 'zustand/middleware';

export interface ComponentBox {
  id: string;
  left: number;
  right: number;
  top: number;
  bottom: number;
}

interface ComponentNavigatorState {
  activeComponentId: string;
  hoveredComponentId: string;
  openedItemIds: Set<string>;
  isOpenedItemId(itemId: string): boolean;
  resetActiveComponentId(): void;
  resetHoveredComponentId(): void;
  setActiveComponentId(componentId: string): void;
  setHoveredComponentId(componentId: string): void;
  toggleOpenedItemId(itemId: string): void;
}

export const useComponentNavigatorStore = create<ComponentNavigatorState>()(
  devtools((set, get) => ({
    activeComponentId: '',
    hoveredComponentId: '',
    openedItemIds: new Set<string>(),
    isOpenedItemId(itemId) {
      const { openedItemIds } = get();
      return openedItemIds.has(itemId);
    },
    resetActiveComponentId() {
      set({ activeComponentId: '' });
    },
    resetHoveredComponentId() {
      set({ hoveredComponentId: '' });
    },
    setActiveComponentId(componentId) {
      set({ activeComponentId: componentId });
    },
    setHoveredComponentId(componentId) {
      set({ hoveredComponentId: componentId });
    },
    toggleOpenedItemId(itemId) {
      const { openedItemIds } = get();

      if (openedItemIds.has(itemId)) {
        openedItemIds.delete(itemId);
      } else {
        openedItemIds.add(itemId);
      }

      set({ openedItemIds });
    }
  }))
);
