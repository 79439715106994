import { nanoid } from 'nanoid';
import Button from 'react-bootstrap/Button';

import type { PlacableComponent } from '../types';

export const CallToActionButtonTypes = ['button', 'submit', 'reset'] as const;

export type CallToActionButtonType = typeof CallToActionButtonTypes[number];

export const CallToActionVariantTypes = [
  'primary',
  'secondary',
  'success',
  'warning',
  'danger',
  'info',
  'light',
  'dark',
  'link',
  'outline-primary',
  'outline-secondary',
  'outline-success',
  'outline-warning',
  'outline-danger',
  'outline-info',
  'outline-light',
  'outline-dark'
] as const;

export type CallToActionVariantType = typeof CallToActionVariantTypes[number];

export interface CallToActionComponentProperties extends PlacableComponent<'CALL_TO_ACTION'> {
  buttonType: CallToActionButtonType;
  text: string;
  variant: CallToActionVariantType;
}

interface CallToActionProps extends CallToActionComponentProperties {
  className?: string;
  name?: string;
}

export function CallToAction({ buttonType, text, ...props }: CallToActionProps) {
  return (
    <Button {...props} type={buttonType}>
      {text}
    </Button>
  );
}

export function callToActionComponentPropertiesFactory(): CallToActionComponentProperties {
  return {
    buttonType: 'submit',
    id: nanoid(10),
    text: 'Call To Action',
    type: 'CALL_TO_ACTION',
    variant: 'primary'
  };
}
