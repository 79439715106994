import type { FC } from 'react';
import { Link } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import { useAuth0 } from '@auth0/auth0-react';

import './Home.scss';

export function Home() {
  return (
    <div className="container cta-container">
      <Card>
        <Card.Body>
          <Card.Title>
            <Home.TitleLine />
          </Card.Title>
          <Card.Text>
            Use this app to bring your custom user profile experience to your customers!
          </Card.Text>
          <Home.CallToAction />
        </Card.Body>
      </Card>
    </div>
  );
}

Home.TitleLine = (() => {
  const { isAuthenticated, user } = useAuth0();
  return isAuthenticated ? <span>Welcome, {user?.name}!</span> : <span>Welcome!</span>;
}) as FC;

Home.TitleLine.displayName = 'Home.TitleLine';

Home.CallToAction = (() => {
  const { isAuthenticated, loginWithRedirect } = useAuth0();
  return isAuthenticated ? (
    <Link className="btn btn-primary" to="/editor">
      Go straigt to the editor!
    </Link>
  ) : (
    <Button variant="primary" onClick={() => loginWithRedirect()}>
      Login
    </Button>
  );
}) as FC;

Home.CallToAction.displayName = 'Home.CallToAction';
