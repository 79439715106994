import type { PaddingProperty } from '../types';

export function defaultPadding(): PaddingProperty {
  return {
    top: 0,
    right: 0,
    bottom: 0,
    left: 0
  };
}
