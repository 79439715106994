import { useEffect, useRef, useState } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { withAuthenticationRequired } from '@auth0/auth0-react';

import { Loading } from '@felixcolaci/cic-profile-page-components';

import { ComponentNavigator } from './componentNavigator';
import { EditorControls } from './editorControls';
import { Preview } from './preview';

import { useConfigStore } from '../../stores/config.store';

import styles from './editor.module.scss';

export const Editor = withAuthenticationRequired(EditorComponent, {
  onRedirecting: () => <Loading />
});

Editor.displayName = 'Editor';

function EditorComponent() {
  const [componentNavigatorWidth, setComponentNavigatorWidth] = useState<number>(0);

  const { loadingContent, loadAsync } = useConfigStore(({ config, refreshFromServerAsync }) => ({
    loadingContent: config.content.length === 0,
    loadAsync: refreshFromServerAsync
  }));

  const hasRefreshedContent = useRef<boolean>(false);

  useEffect(() => {
    if (!hasRefreshedContent.current) {
      hasRefreshedContent.current = true;
      loadAsync().catch(console.error);
    }
  }, [loadAsync]);

  return (
    <DndProvider backend={HTML5Backend}>
      {loadingContent ? (
        <Loading />
      ) : (
        <div className={styles.editor}>
          <ComponentNavigator setComponentNavigatorWidth={setComponentNavigatorWidth} />
          <Preview consumedBrowserWidth={componentNavigatorWidth} />
          <EditorControls />
        </div>
      )}
    </DndProvider>
  );
}
