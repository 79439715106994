import { useAuth0 } from '@auth0/auth0-react';

export function NavBarAuthButton() {
  const { isAuthenticated } = useAuth0();

  return isAuthenticated ? <LogoutButton /> : <LoginButton />;
}

function LoginButton() {
  const { loginWithRedirect } = useAuth0();
  return (
    <button className="btn btn-outline-light btn-sm" onClick={() => loginWithRedirect()}>
      Log In
    </button>
  );
}

function LogoutButton() {
  const { logout } = useAuth0();
  return (
    <button
      className="btn btn-outline-light btn-sm"
      onClick={() =>
        logout({
          returnTo: window.location.origin
        })
      }>
      Log out
    </button>
  );
}
