import { useEffect } from 'react';
import { Route, Routes, BrowserRouter } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';

import { Loading } from '@felixcolaci/cic-profile-page-components';

import { Header } from './header';

import { Editor } from '../routes/editor';
import { Home } from '../routes/home';

import { useProfileStore } from '../stores/profile.store';

export type AutosaveStatus = 'error' | 'idle' | 'pending' | 'success';

export function App() {
  const { isLoading, error, user } = useAuth0();
  const { profile, getProfileAsync } = useProfileStore(({ profile, getProfileAsync }) => ({
    profile,
    getProfileAsync
  }));

  useEffect(() => {
    if (!profile && user?.sub) {
      getProfileAsync(user?.sub);
    }
  }, [profile, user, getProfileAsync]);

  if (error) {
    return <div>Oops... {error.message}</div>;
  }

  if (isLoading) {
    return <Loading />;
  }

  return (
    <BrowserRouter>
      <Header />
      <Routes>
        <Route path="/editor" element={<Editor />} />
        <Route path="/" element={<Home />} />
      </Routes>
    </BrowserRouter>
  );
}
