import Button from 'react-bootstrap/Button';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';

import type {
  ComponentType,
  EditableComponentProperties,
  LayoutComponentProperties
} from '@felixcolaci/cic-profile-page-components';
import {
  bootstrapComponent,
  ComponentTypes,
  PrimitiveComponentTypes,
  titleCase
} from '@felixcolaci/cic-profile-page-components';

import {
  TooltipProvider,
  TooltipProviderChildrenProps
} from '../../../../../shared/tooltipProvider';

import { useComponentNavigatorStore } from '../../../../../stores/componentNavigator.store';
import { useConfigStore } from '../../../../../stores/config.store';

import { ComponentTree } from '../../componentTree';

import styles from './layoutNavigatorItem.module.scss';

interface LayoutNavigatorItemProps {
  activeComponentId: string;
  className?: string;
  component: LayoutComponentProperties<Array<EditableComponentProperties>>;
  hoveredComponentId: string;
  indentationMultiple: number;
  getIndentationPadding(indentationMultiple: number): string;
  resetHoveredComponentId(): void;
  setHoveredComponentId(componentId: string): () => void;
  updateActiveComponent(component: EditableComponentProperties): () => void;
}

export function LayoutNavigatorItem({
  activeComponentId,
  className,
  component,
  hoveredComponentId,
  indentationMultiple,
  getIndentationPadding,
  resetHoveredComponentId,
  setHoveredComponentId,
  updateActiveComponent
}: LayoutNavigatorItemProps) {
  const { isOpenedItemId, toggleOpenedItemId } = useComponentNavigatorStore(
    ({ isOpenedItemId, toggleOpenedItemId }) => ({
      isOpenedItemId,
      toggleOpenedItemId: (itemId: string) => () => toggleOpenedItemId(itemId)
    })
  );

  const { appendComponent, removeComponent } = useConfigStore(
    ({ appendComponent, removeComponent }) => ({
      appendComponent: (componentToAppend: EditableComponentProperties, parentId: string) => () =>
        appendComponent(componentToAppend, parentId),
      removeComponent: (componentId: string) => () => removeComponent(componentId)
    })
  );

  const childrenExpanded = isOpenedItemId(component.id);

  const toggleChildrenExpandedIconHeight = 8;
  const toggleChildrenExpandedIconWidth = 8;

  return (
    <>
      <div
        className={`${className} ${styles.layoutAction} ${
          component.id === activeComponentId ? styles.activeAction : ``
        }`}
        style={{ padding: getIndentationPadding(indentationMultiple) }}>
        <button
          className={styles.toggleChildrenExpandedAction}
          style={{
            height: toggleChildrenExpandedIconHeight,
            width: toggleChildrenExpandedIconWidth
          }}
          type="button"
          onClick={toggleOpenedItemId(component.id)}>
          <ToggleChildrenExpandedIcon
            expanded={childrenExpanded}
            height={toggleChildrenExpandedIconHeight}
            width={toggleChildrenExpandedIconWidth}
          />
        </button>
        <button
          className={component.id === hoveredComponentId ? styles.hoveredAction : undefined}
          type="button"
          onClick={updateActiveComponent(component)}
          onMouseLeave={resetHoveredComponentId}
          onMouseOver={setHoveredComponentId(component.id)}>
          {titleCase(component.type)}
        </button>
        <div className={styles.componentActions}>
          <TooltipProvider label="Add component to layout">
            {({
              label,
              ref,
              hideTooltip,
              showTooltip
            }: TooltipProviderChildrenProps<HTMLButtonElement>) => (
              <OverlayTrigger
                overlay={
                  <Popover>
                    <Popover.Header as="h3">Component to add</Popover.Header>
                    <Popover.Body className={styles.popover}>
                      {[
                        ...PrimitiveComponentTypes,
                        ...ComponentTypes.filter(
                          (componentType): componentType is Exclude<ComponentType, 'PRIMITIVE'> =>
                            componentType !== 'PRIMITIVE'
                        )
                      ].map((componentType) => (
                        <Button
                          key={componentType}
                          type="button"
                          variant="outline-primary"
                          onClick={appendComponent(
                            bootstrapComponent(componentType),
                            component.id
                          )}>
                          {titleCase(componentType)}
                        </Button>
                      ))}
                    </Popover.Body>
                  </Popover>
                }
                placement="right"
                trigger="click">
                <button
                  aria-label={label}
                  ref={ref}
                  type="button"
                  onMouseLeave={hideTooltip}
                  onMouseOver={showTooltip}>
                  <i className="bi bi-plus-circle" />
                </button>
              </OverlayTrigger>
            )}
          </TooltipProvider>
          <TooltipProvider label="Delete layout">
            {({
              label,
              ref,
              hideTooltip,
              showTooltip
            }: TooltipProviderChildrenProps<HTMLButtonElement>) => (
              <button
                aria-label={label}
                ref={ref}
                type="button"
                onClick={removeComponent(component.id)}
                onMouseLeave={hideTooltip}
                onMouseOver={showTooltip}>
                <i className="bi bi-trash" />
              </button>
            )}
          </TooltipProvider>
        </div>
      </div>
      {childrenExpanded && (
        <div className={styles.componentList}>
          {component.content.map((item) => (
            <ComponentTree
              key={item.id}
              className={styles.nestedComponent}
              component={item}
              indentationMultiple={indentationMultiple + 1}
            />
          ))}
        </div>
      )}
    </>
  );
}

interface ToggleChildrenExpandedIconProps {
  expanded: boolean;
  height: number;
  width: number;
}

function ToggleChildrenExpandedIcon({ expanded, height, width }: ToggleChildrenExpandedIconProps) {
  return (
    <svg
      fill="#ffffff"
      height={height}
      width={width}
      viewBox={`0 0 ${height} ${width}`}
      style={{ minHeight: height, minWidth: width }}>
      {expanded ? (
        <path d={`M0,0 L${width / 2},${height} L${width},0`} />
      ) : (
        <path d={`M0,0 V0,${height} L${width},${height / 2}`} />
      )}
    </svg>
  );
}
